.mapboxgl-ctrl button.mapboxgl-ctrl-layer .mapboxgl-ctrl-icon {
  background: no-repeat center/17px url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='layer-group' class='svg-inline--fa fa-layer-group fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23333' d='M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z'%3E%3C/path%3E%3C/svg%3E");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-layer-active .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='layer-group' class='svg-inline--fa fa-layer-group fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%2333B5E5' d='M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z'%3E%3C/path%3E%3C/svg%3E");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor {
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
  color: #333;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor-active {
  color: #33B5E5;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor-4 .mapboxgl-ctrl-icon::after {
  content: '4';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor-3 .mapboxgl-ctrl-icon::after {
  content: '3';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor-2 .mapboxgl-ctrl-icon::after {
  content: '2';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor-1 .mapboxgl-ctrl-icon::after {
  content: '1';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor-0 .mapboxgl-ctrl-icon::after {
  content: '0';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor--1 .mapboxgl-ctrl-icon::after {
  content: '-1';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor--2 .mapboxgl-ctrl-icon::after {
  content: '-2';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor--3 .mapboxgl-ctrl-icon::after {
  content: '-3';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor--4 .mapboxgl-ctrl-icon::after {
  content: '-4';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor--5 .mapboxgl-ctrl-icon::after {
  content: '-5';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor--6 .mapboxgl-ctrl-icon::after {
  content: '-6';
}

.mapboxgl-ctrl button.mapboxgl-ctrl-floor--7 .mapboxgl-ctrl-icon::after {
  content: '-7';
}