.image-gallery {
    width: 100%;
    height: auto;
}

.image-gallery-slide img {
    width: 100%;
    height: 100vh;
    max-height: 300px;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
    min-height: 300px
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh;
    object-fit: contain;
}

.image-gallery-left-nav {
    font-size: 1em;
}

.image-gallery-right-nav {
    font-size: 1em;
}

.image-gallery-fullscreen-button {
    font-size: 0.4em;
}

.image-gallery-svg {
    max-height: 36px !important;
    max-width: 36px !important;
  }