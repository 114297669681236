/* Marker tweaks */
.mapboxgl-popup-close-button {
    display: none;
  }
  
  .mapboxgl-popup-content {
    font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', Sans-serif;
    padding: 0;
    max-width: 300px;
  }
  
  .mapboxgl-popup-content-wrapper {
    padding: 1%;
  }
  
  .mapboxgl-popup-content h3 {
    background: #91c949;
    color: #fff;
    margin: 0;
    display: block;
    padding: 10px;
    border-radius: 3px 3px 0 0;
    font-weight: 700;
    margin-top: -15px;
  }
  
  .mapboxgl-popup-content h4 {
    margin: 0;
    display: block;
    padding: 10px;
    font-weight: 400;

  }
  
  .mapboxgl-popup-content div {
  }
  
  .mapboxgl-container .leaflet-marker-icon {
    cursor: pointer;
  }
  
  .mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
    margin-top: 15px;
  }
  
  .mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
    border-bottom-color: #91c949;
  }